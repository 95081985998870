.wrapper-office {
    background-color: #f7f7f7;
    height: 100%;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    overflow: auto;
}

.wrapper-office header {
    height: 50px;
    background-color: #fff;
    display: flex;
    align-items: center;
    /* box-shadow: rgba(0, 0, 0, 0.8) 0px 5px 10px 0; */
    z-index: 15;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    box-shadow: 0px 6px 15px -8px rgba(0, 0, 0, 0.1);
}

.wrapper-office header .logo img {
    max-height: 50px;
}

.wrapper-office header .logo-police img {
    height: 50px;
    width: auto;
}

.wrapper-office header .icons .icons-item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
}

.wrapper-office header .icons .icons-item.profile {
    width: auto;
    padding: 0 20px;
}

.wrapper-office header .icons .icons-item.profile span {
    color: #1A49AD;
}

.wrapper-office header .icons .icons-item i {
    color: #1A49AD;
    font-size: 18px;
}

.wrapper-office header .icons .icons-item.logout i {
    color: #990000;
    font-size: 18px;
}

.wrapper-office header .icons .icons-item.profile i {
    margin-right: 10px;
}

.wrapper-office header .icons .icons-item:hover {
    background-color: #008CFF;
}

.wrapper-office header .icons .icons-item:hover i,
.wrapper-office header .icons .icons-item:hover span {
    color: #fff;

}

.wrapper-office header .icons .icons-item.logout:hover {
    background-color: #990000;
}

.wrapper-office header .icons .icons-item.logout:hover i,
.wrapper-office header .icons .icons-item.logout:hover span {
    color: #fff;
}

.wrapper-office .menu {
    position: fixed;
    height: 100%;
    top: 50px;
    width: 230px;
    z-index: 16;
    background-color: #fff;
    box-shadow: 6px 0px 15px -8px rgba(0, 0, 0, 0.1);
}

.wrapper-office .menu .nav {
    display: block;
    list-style: none;
    margin: 0;
    padding: 0;
}

.wrapper-office .menu .nav li {
    display: block;
    cursor: pointer;
    margin-top: 10px;
}

.wrapper-office .menu .nav li:nth-child(1),
.wrapper-office .menu .nav li:nth-child(2) {
    margin-top: 0 !important;
}


.wrapper-office .menu .nav li .link-main,
.wrapper-office .menu .nav li .link-sub {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 16px;
}

.wrapper-office .menu .nav li .link-main span {
    font-size: 14px;
    color: #44444B;
}

.wrapper-office .menu .nav li .link-main:hover,
.wrapper-office .menu .nav li .link-main:active,
.wrapper-office .menu .nav li .link-main:link,
.wrapper-office .menu .nav li .link-sub:hover,
.wrapper-office .menu .nav li .link-sub:active,
.wrapper-office .menu .nav li .link-sub:link {
    text-decoration: none;
}

.wrapper-office .menu .nav li .link-main i {
    font-size: 14px;
    color: #44444B;
}

.wrapper-office .menu .nav li i,
.wrapper-office .menu .nav li i:before {
    text-align: center;
    width: 30px;
}

.wrapper-office .menu .nav li:hover,
.wrapper-office .menu .nav li.active {
    background-color: #e9edf7;
}

.wrapper-office .menu .nav li.home {
    background-color: #999;
}

.wrapper-office .menu .nav li.home .link-main span,
.wrapper-office .menu .nav li.home .link-main i {
    color: #fff !important;
    font-weight: bold;
}

.wrapper-office .menu .nav li.active .link-main,
.wrapper-office .menu .sub li.active .link-sub {
    border-right: 5px solid #1a49ad;
}

.wrapper-office .menu .nav li.active .link-main span,
.wrapper-office .menu .sub li.active .link-sub span {
    font-weight: bold;
}

.wrapper-office .menu .nav li.active .sub {
    display: block !important;
}

.wrapper-office .menu .nav .sub {
    margin: 0;
    padding: 0;
    display: none;
    list-style: none;
    padding-left: 25px;
}

.wrapper-office .menu .nav .sub li {
    margin-top: 0 !important;
}

.wrapper-office .content {
    margin-left: 230px;
    padding-top: 50px;
}

.wrapper-office header .company-name {
    background-color: #F4F5F7;
    border-radius: 4px;
    padding: 4px 15px;
    margin-left: 20px;
    font-weight: 700;
}