.wrapper-office .results .box-bg {
    width: 100%;
    background-color: #F4F5F7;
    border-radius: 4px;
    padding: 8px;
}

.wrapper-office .results .warn .v1 {
    font-weight: 700;
}

.wrapper-office .results .warn .v2 {
    font-size: 12px;
    font-weight: 500;
    color: #56565D;
}

.wrapper-office .results .notes .v1 {
    color: #9B9EA5;
    font-size: 20px;
}

.wrapper-office .results .topic {
    display: flex;
    flex-direction: column;
}

.wrapper-office .results .topic .v1 {
    font-size: 12px;
    font-weight: 500;
    color: #44444B;
    display: flex;
    align-items: center;
    line-height: 16px;
}

.wrapper-office .results .topic .v2 {
    font-size: 12px;
    font-weight: 500;
    color: #56565D;
}

.wrapper-office .results .topic .v2.bold {
    font-weight: 700 !important;
}

.wrapper-office .results h5 {
    color: #44444B;
    font-weight: 600;
    font-size: 16px;
}

.wrapper-office .results .btn-outline-primary i {
    color: #007bff;
}
.wrapper-office .results .btn-outline-primary:hover i,
.wrapper-office .results .btn-outline-danger:hover i {
    color: #fff;
}
.wrapper-office .results .btn-outline-danger i {
    color: #dc3545;
}

.mark-keywords {
    display: inline-block;
    background-color: #000099;
    color: #fff;
}

.mark-additionals {
    display: inline-block;
    background-color: #d4a600;
    color: #fff;
}